import { useTranslation } from "react-i18next";
import NewsletterForm from "./NewsletterForm/NewsletterForm.component";

function Newsletter() {

    const { t } = useTranslation();

    return (
        <section className="newsletter">
            <div className="newsletter__container">
                <h2 data-aos="fade-up" className="newsletter__title section-title">Newsletter</h2>
                <p data-aos="fade-up" className="newsletter__paragraph">{ t("Newsletter paragraph") }</p>
                <NewsletterForm/>
            </div>    
        </section>
    )
}

export default (Newsletter);