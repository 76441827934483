import { useTranslation } from "react-i18next";
import RoadmapElement from "./RoadmapElement/RoadmapElement.component";
import RoadmapList from "./RoadmapElement/RoadmapList.component";

function Roadmap() {

    const { t } = useTranslation();

    return (
        <section className="roadmap">
            <div className="roadmap__container">
                <h2 data-aos="fade-up" className="roadmap__title section-title">{t("Roadmap Title")}</h2>
                <div data-aos="fade-up" className="roadmap__wrapper">
                    <RoadmapElement checked={false} period={t("Roadmap Element 1 Period")} title={t("Roadmap Element 1 Title")}>
                        <RoadmapList items={t("Roadmap Element 1 List", { returnObjects: true })} />
                    </RoadmapElement>
                    <RoadmapElement checked={false} period={t("Roadmap Element 2 Period")} title={t("Roadmap Element 2 Title")}>
                        <RoadmapList items={t("Roadmap Element 2 List", { returnObjects: true })} />
                    </RoadmapElement>
                    <RoadmapElement checked={false} period={t("Roadmap Element 3 Period")} title={t("Roadmap Element 3 Title")}>
                        <RoadmapList items={t("Roadmap Element 3 List", { returnObjects: true })} />
                    </RoadmapElement>
                </div>
            </div>
        </section>
    );
}

export default (Roadmap);