import image from "../../assets/images/token-distribution.png";
import Button from "./Button/Button.component";
import { useTranslation } from "react-i18next";
import Tokenomics from "../../assets/files/tokenomics.pdf";
import Whitepaper from "../../assets/files/whitepaper.pdf";
import PitchDeck from "../../assets/files/pitchdeck.pdf";
function Token() {
  const { t } = useTranslation();

  return (
    <section id="token" className="token">
      <div className="token__container">
        <h2 data-aos="fade-up" className="token__title section-title">
          Token
        </h2>
        <div className="token__wrapper">
          <div className="token__image">
            <img src={image} alt="member-image" />
          </div>
          <div className="token__content">
            <p data-aos="fade-up" className="token__content__text text">
              {t("Token Content")}
            </p>
            <div className="token__buttons">
              <Button className="hero__button token__button" href={Tokenomics}>
                {t("Token Button")}
              </Button>

              <Button className="hero__button token__button" href={Whitepaper}>
                {t("Token Button 2")}
              </Button>

              <Button className="hero__button token__button" href={PitchDeck}>
                {t("Token Button 3")}
              </Button>
            </div>
          </div>
        </div>
      </div>
    </section>
  );
}

export default Token;
