import { ReactComponent as Linkedin } from "../../assets/icons/linkedIn.svg";
import member0 from "../../assets/images/m-bochenek.png";
import member1 from "../../assets/images/d-barski.png";
import member2 from "../../assets/images/t-smus.png";
import member3 from "../../assets/images/a-kozdeba.png";
import member4 from "../../assets/images/user.png";
import member5 from "../../assets/images/m-bochenek-2.png";
import member6 from "../../assets/images/p-wolan.png";
import member7 from "../../assets/images/p-kasumovic.png";
import member8 from "../../assets/images/p-misiak.png";
import doorImage from "../../assets/images/hover_image_door.png";
import { useTranslation } from "react-i18next";

function Team() {

    const { t } = useTranslation();

    return (
        <section className="team">
            <div className="team__bg" />
            <div className="team__container">
                <h2 data-aos="fade-up" className="team__title section-title">{t("Team Title")}</h2>
                <div className="team__grid">
                    <MemberCard image={member0} name="Marcin Bochenek" role="Founder" linkedInLink="https://www.linkedin.com/in/marcin-bochenek-5a8079130/" />
                    <MemberCard image={member1} name="Dariusz Barski" role="Founder" linkedInLink="https://www.linkedin.com/in/dariusz-barski-b95a1456/" />
                    <MemberCard image={member3} name="Andrzej Kozdęba" role="Web Manager" linkedInLink="https://www.linkedin.com/in/andrzej-kozd%C4%99ba-63227466/" />
                    <MemberCard image={member4} name="Przemysław Prończuk" role="CMO" linkedInLink="https://www.linkedin.com/in/przemekpronczuk/" />
                    <MemberCard image={member5} name="Marcin Bochenek" role="Director of North Poland" linkedInLink="https://www.linkedin.com/in/marcin-bochenek-95542653/" />
                    <MemberCard image={member6} name="Paweł Wolan" role="Director of East Poland" linkedInLink="" />
                    <MemberCard image={member7} name="Piotr Kasumović" role="Director of Central Poland" linkedInLink="https://www.linkedin.com/in/pkasumovic/" />
                    <MemberCard image={member8} name="Piotr Misiak" role="Cryptocurrency advisor" linkedInLink="https://www.linkedin.com/in/piotr-misiak-11b543181/" />
                </div>
            </div>
        </section>
    );
}

type Props = {
    className?: string
    image: any
    name: string
    role: string
    linkedInLink: string
}

function MemberCard(props: Props) {
    const { className = "", image, name, role, linkedInLink } = props;
    return (
        <div data-aos="fade-up" className={`member ${className}`}>
            <div className="member__img-wrapper">
                <div className="member__image member__image--down">
                    <img src={image} alt="member-image" />
                </div>
                <div className="member__image member__image--up">
                    <img src={doorImage} alt="door-image" />
                </div>
            </div>
            <p className="member__name">{name}</p>
            <p className="member__role">{role}</p>
            <a className="member__link" href={linkedInLink} target="_blank">
                <Linkedin />
            </a>
        </div>
    );
}

export default (Team);
