// import { useTranslation } from "react-i18next";
import { Autoplay, Scrollbar } from 'swiper';
import { Swiper, SwiperSlide } from 'swiper/react';
import slide1 from '../../assets/images/slides/1.png';
import slide2 from '../../assets/images/slides/2.png';
import slide3 from '../../assets/images/slides/3.png';
import slide4 from '../../assets/images/slides/4.png';
import slide5 from '../../assets/images/slides/5.png';
import slide6 from '../../assets/images/slides/6.png';
import slide7 from '../../assets/images/slides/7.png';
import slide8 from '../../assets/images/slides/8.png';
import slide9 from '../../assets/images/slides/9.png';
import slide10 from '../../assets/images/slides/10.png';
import slide11 from '../../assets/images/slides/11.png';
import slide12 from '../../assets/images/slides/12.png';

import 'swiper/css';

function Slider() {
    return (
        <section className="slider">
            <div className="slider__container">
                <Swiper
                    modules={[Autoplay, Scrollbar]}
                    slidesPerView={1}
                    autoplay={{delay: 2000, disableOnInteraction: false}}
                    scrollbar={{ draggable: true }}
                    breakpoints={{
                        768: {
                            slidesPerView: 2,
                            spaceBetween: 20
                        },
                        1200: {
                            slidesPerView: 3,
                            spaceBetween: 40
                        }
                    }}
                >
                    <SwiperSlide><img src={ slide1 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide2 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide3 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide4 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide5 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide6 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide7 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide8 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide9 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide10 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide11 } alt="NFT example" /></SwiperSlide>
                    <SwiperSlide><img src={ slide12 } alt="NFT example" /></SwiperSlide>
                </Swiper>     
            </div>   
        </section>
    );
}

export default (Slider);