import React, { useEffect } from "react";
import "../../assets/styles/index.scss";
import Header from "../components/Header.component";
import About from "../components/About.component";
import Hero from "../components/Hero.component";
import CallToAction from "../components/CallToAction.component";
import Team from "../components/Team.component";
// import Faq from "../components/Faq.component";
import Footer from "../components/Footer.component";
import Roadmap from "../components/Roadmap.component";
import Benefits from "../components/Benefits.component";
import Slider from "../components/Slider.component";
import Newsletter from "../components/Newsletter.component";
import AOS from "aos";
import "aos/dist/aos.css";
import Whitepaper from "../components/Whitepaper.component";
import Partners from "../components/Partners.component";
import Token from "../components/Token.component";

function App() {
  useEffect(() => {
    AOS.init();
    AOS.refresh();
  }, []);

  return (
    <>
      <Header />
      <Hero />
      <About />
      <Roadmap />
      <Benefits />
      <Whitepaper />
      <Team />
      <Token />
      <Partners />
      <Newsletter />
      <Slider />
      <Footer />
    </>
  );
}
//  <CallToAction/> removed
export default App;
