import logo from "../../assets/images/logo.svg";
import { Link } from "react-scroll";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";
import { ReactComponent as Medium } from '../../assets/icons/medium.svg';
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";

function Footer() {
    return (
        <footer className="footer">
            <div className="footer__container">
                <div className="footer__col footer__col--first">
                    <Link to="hero" smooth={ true } className="footer__logo">
                        <img src={ logo } alt="logo"/>
                    </Link>
                    <h2 className="title">Flat for Flip</h2>
                </div>
                <div className="footer__col footer__col--second footer__socials">
                    <a href="https://twitter.com/FlatforFlipNFT" target="_blank" className="footer__socials__item footer__socials__item--twitter">
                        <Twitter/>
                    </a>
                    <a href="https://discord.com/invite/4qZvvpEXbh" target="_blank" className="footer__socials__item footer__socials__item--discord">
                        <Discord/>
                    </a>
                    <a href="https://t.me/flatforflip_community" target="_blank" className="footer__socials__item footer__socials__item--discord">
                        <Telegram/>
                    </a>
                </div>
                <div className="footer__col footer__col--third footer__terms">
                    <p><b>© 2020 Flat for Flip - All Rights Reserved.</b></p>
                    <p><b>Terms & Conditions</b></p>
                </div>
            </div>
        </footer>
    );
}

export default (Footer);
