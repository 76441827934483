import React from "react";
import { ReactComponent as RightArrow } from "../../../assets/icons/arrow_right.svg";

type Props = {
    className?: string
    children: string
    href: string
}

function Button(props: Props) {
    const { children, className = "", href } = props;
    return (
        <a data-aos="fade-up" href={ href } target="_blank" className={ `${ className } button` }>
            { children }
            <RightArrow className="button__arrow"/>
            <div className="button__background"/>
            <div className="button__shadow"/>
        </a>
    );
}

export default (Button);
