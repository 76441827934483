import { useTranslation } from "react-i18next";
import SingleBenefit from "./SingleBenefit/SingleBenefit.component";

function Benefits() {

     const { t } = useTranslation();

     return (
        <section className="benefits">
            <div className="benefits__container">
                <h2 data-aos="fade-up" className="benefits__title section-title">{ t("Benefits Title") }</h2>
                <div className="benefits__wrapper">
                    <div data-aos="fade-up" className="benefits__wrapper-row">
                        <SingleBenefit title={ t("Single Benefit 1 Title") } content={ t("Single Benefit 1 Content", {returnObjects: true}) }/>
                        <SingleBenefit title={ t("Single Benefit 2 Title") } content={ t("Single Benefit 2 Content", {returnObjects: true}) }/>
                        <SingleBenefit title={ t("Single Benefit 3 Title") } content={ t("Single Benefit 3 Content", {returnObjects: true}) }/>    
                    </div>
                    <div data-aos="fade-up" className="benefits__wrapper-row">
                        <SingleBenefit title={ t("Single Benefit 4 Title") } content={ t("Single Benefit 4 Content", {returnObjects: true}) }/>
                        <SingleBenefit title={ t("Single Benefit 5 Title") } content={ t("Single Benefit 5 Content", {returnObjects: true}) }/>
                        <SingleBenefit title={ t("Single Benefit 6 Title") } content={ t("Single Benefit 6 Content", {returnObjects: true}) }/>    
                    </div>
                    <div data-aos="fade-up" className="benefits__wrapper-row">
                        <SingleBenefit title={ t("Single Benefit 7 Title") } content={ t("Single Benefit 7 Content", {returnObjects: true}) }/>
                        <SingleBenefit title={ t("Single Benefit 8 Title") } content={ t("Single Benefit 8 Content", {returnObjects: true}) }/>
                        <SingleBenefit title={ t("Single Benefit 9 Title") } content={ t("Single Benefit 9 Content", {returnObjects: true}) }/>    
                    </div>
                </div>
            </div>
        </section>
     );
}

export default (Benefits);