import { ReactComponent as Key } from "../../../assets/icons/roadmap_key.svg";

type Props = {
    title: string
    content: string[]
};

function SingleBenefit(props: Props) {
    const { title, content } = props;
    
    return (
        <div className="single-benefit">
            <Key className="single-benefit__key"/>
            <h4 className="single-benefit__title">{ title }</h4>
            {
                content.map((item, index) => {
                    return (
                        <p className="single-benefit__paragraph" key={ index }>{ item }</p>
                    )
                })
            }
        </div>
    );
}

export default (SingleBenefit);