import logo from "../../assets/images/logo.svg";
import { Link } from "react-scroll";
import { ReactComponent as Discord } from "../../assets/icons/discord.svg";
import { ReactComponent as Twitter } from "../../assets/icons/twitter.svg";
import { ReactComponent as Medium } from "../../assets/icons/medium.svg";
import { ReactComponent as RightArrow } from "../../assets/icons/arrow_right.svg";
import { ReactComponent as Telegram } from "../../assets/icons/telegram.svg";
import { useTranslation } from "react-i18next";
import Button from "./Button/Button.component";
import LanguageSwitchComponent from "./LanguageSwitch.component";

function Header() {

    const { t } = useTranslation();

    return (
        <header className="header">
            <div className="header__container">
                <nav className="header__nav nav">
                    <Link to="hero" smooth={true} className="nav__link nav__logo">
                        <img src={logo} alt="logo" />
                    </Link>
                    <ul className="nav__list">
                        <li className="nav__item">
                            <Link className="nav__link" to="about" smooth={true} offset={-150}>
                                {t("About Title")}
                            </Link>
                        </li>
                        <li className="nav__item">
                            <Link className="nav__link" to="whitepaper" smooth={true} offset={-110}>
                                Whitepaper
                            </Link>
                        </li>
                        <li className="nav__item">
                            <Link className="nav__link" to="team" smooth={true} offset={-110}>
                                {t("Team Title")}
                            </Link>
                        </li>
                        <li className="nav__item">
                            <Link className="nav__link" to="token" smooth={true} offset={-200}>
                                Token
                            </Link>
                        </li>
                        <li className="nav__item">
                            <Link className="nav__link" to="partners" smooth={true} offset={-110}>
                                {t("Partners Title")}
                            </Link>
                        </li>
                    </ul>
                </nav>
                <div className="header__socials">
                    <a href="https://twitter.com/FlatforFlipNFT" target="_blank" className="header__socials__item header__socials__item--twitter">
                        <Twitter />
                    </a>
                    <a href="https://discord.com/invite/4qZvvpEXbh" target="_blank" className="header__socials__item header__socials__item--discord">
                        <Discord />
                    </a>
                    <a href="https://t.me/flatforflip_community" target="_blank" className="header__socials__item header__socials__item--discord">
                        <Telegram />
                    </a>
                    <a href="https://www.fffplatform.com/" target="_blank" className="button  --header-small">
                        {t("Platform")}
                        <RightArrow className="button__arrow"/>
                        <div className="button__background"/>
                        <div className="button__shadow"/>
                    </a>
                </div>

                <div className="header__language">
                    <LanguageSwitchComponent />
                </div>
            </div>
        </header>
    );
}

export default (Header);
