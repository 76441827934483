import { useTranslation } from "react-i18next";
import Button from "./Button/Button.component";
import Pdf from "../../assets/files/whitepaper.pdf";

function Whitepaper() {

    const { t } = useTranslation();

    return (
        <section className="whitepaper">
            <div className="whitepaper__container">
                <h2 data-aos="fade-up" className="whitepaper__title section-title">Whitepaper</h2>
                <p data-aos="fade-up" className="whitepaper__paragraph">{ t("Whitepaper Paragraph") }</p>
                <Button href={Pdf}>{ t("Whitepaper Button") }</Button>
            </div>    
        </section>
    );
}

export default (Whitepaper);