import Mailchimp from 'react-mailchimp-form';
import env from "react-dotenv";

function NewsletterForm() {

    const postUrl = `https://flatforflip.us14.list-manage.com/subscribe/post?u=${env.MAILCHIMP_U}&id=${env.MAILCHIMP_ID}`;

    return (
        <div data-aos="fade-up" className="mc-form__container">
            <Mailchimp
                action={postUrl}
                fields={[
                    {
                        name: 'EMAIL',
                        placeholder: 'Email',
                        type: 'email',
                        required: true
                    }
                ]}
                messages = {
                    {
                        sending: "Sending...",
                        success: "Thank you for subscribing!",
                        error: "An unexpected internal error has occurred.",
                        empty: "You must write an e-mail.",
                        duplicate: "Too many subscribe attempts for this email address",
                        button: "OK!"
                    }
                }
            />
        </div>
    )
}

export default (NewsletterForm)