import { ReactComponent as Key } from "../../../assets/icons/roadmap_key.svg";
import classNames from "classnames";

type Props = {
    checked: boolean
    period: string
    title: string
    children: React.ReactNode
};

function RoadmapElement(props: Props) {
    const { checked, period, title, children } = props;

    return (
        <div className={`roadmap__element ${ classNames({ 'roadmap__element--checked': checked })}`}>
            <Key className="roadmap__element-key"/>
            <h3 className="roadmap__element-period">{ period }</h3>
            <div className="roadmap__element-content">
                <h4 className="roadmap__element-title">{ title }</h4>
                { children }
            </div>
        </div>
    );
}

export default (RoadmapElement);