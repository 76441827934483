import doorImage from "../../../assets/images/hover_image_door.png";

type PositionVariant =
    | "left-top"
    | "right-bottom";

type Props = {
    image: any
    box: any
    position: PositionVariant
};

function HoverImage(props: Props) {
    const { image, box, position } = props;

    return (
        <div className="hover-image">
            <div className="hover-image__image hover-image__image--up">
                <img src={ doorImage } alt="doors"/>
            </div>
            <div className="hover-image__image hover-image__image--down">
                <img src={ image } alt="hover-image"/>
            </div>
            <div className="hover-image__background"/>
            <div className={ `hover-image__box hover-image__box--${ position }` }>
                <img src={ box } alt="box"/>
            </div>
        </div>
    );
}

export default (HoverImage);
