import englishFlag from "../../assets/images/uk_flag.png";
import polishFlag from "../../assets/images/pl_flag.png";
import { useState } from "react";
import { EnumDictionary } from "../types/util";
import { useTranslation } from 'react-i18next';

enum Language {
    POLISH = "pl",
    ENGLISH = "en"
}

function LanguageSwitchComponent() {

    const [ active, setActive ] = useState(Language.ENGLISH);
    const { i18n } = useTranslation();

    const _getInactive = () => {
        if (active === Language.ENGLISH) {
            return Language.POLISH;
        } else {
            return Language.ENGLISH;
        }
    };

    const _handleClick = () => {
        const inActive = _getInactive();
        setActive(inActive);
        i18n.changeLanguage(inActive);
    };

    return (
        <div className="language-switch" onClick={ _handleClick }>
            <div className="language-switch__box">
                <LanguageSwitchBoxContent variant={ active } />
            </div>

            <div className="language-switch__tooltip">
                <div className="language-switch__box language-switch__box--tooltip">
                    <LanguageSwitchBoxContent variant={ _getInactive() } />
                </div>
            </div>
        </div>
    );
}

type LanguageSwitchBoxContentProps = {
    variant: Language
}

const languageDictionary: EnumDictionary<Language, any> = {
    [ Language.ENGLISH ]: englishFlag,
    [ Language.POLISH ]: polishFlag
};

function LanguageSwitchBoxContent({ variant }: LanguageSwitchBoxContentProps) {
    return (
        <>
            <span className="language-switch__text">{ variant }</span>
            <img className="language-switch__image" src={ languageDictionary[ variant ] } alt="polish-flag"/>
        </>
    );
}

export default (LanguageSwitchComponent);
