type Props = {
    items: string[]
}

function RoadmapList(props: Props) {
    const items = props.items;

    return (
        <ul className="roadmap__list">
            {
                items.map((item, index) => {
                    return (
                        <li key={ index } className="roadmap__list-item">{ item }</li>
                    )
                })
            }
        </ul>
    );
}

export default (RoadmapList);