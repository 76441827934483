import Button from "./Button/Button.component";
import { useTranslation } from "react-i18next";
import Whitepaper from "../../assets/files/whitepaper.pdf";

function Hero() {
  const { t } = useTranslation();

  return (
    <section className="hero">
      <div className="hero__container">
        <div className="hero__content">
          <span data-aos="fade-right" className="hero__subtitle">
            {t("Hero Subtitle")}
          </span>
          <h1 data-aos="fade-right" className="title">
            {t("Hero Title 1")}
            <span className="title--secondary">{t("Hero Title 2")}</span>
            {t("Hero Title 3")}
            <span className="title--secondary">{t("Hero Title 4")}</span>
            {t("Hero Title 5")}
            <span className="title--secondary">{t("Hero Title 6")}</span>
            {t("Hero Title 7")}
          </h1>
          <Button className="hero__button" href={Whitepaper}>
            {t("Hero Button")}
          </Button>
          <Button className="hero__button" href={t("Discord Button Link")}>
            {t("Discord Button")}
          </Button>
        </div>
      </div>
    </section>
  );
}

export default Hero;
