import partner1 from "../../assets/images/comparic.png";
import partner2 from "../../assets/images/reach.png";
import partner3 from "../../assets/images/reliance.png";
import partner4 from "../../assets/images/samana.png";
import partner5 from "../../assets/images/cashify.jpg";
import partner6 from "../../assets/images/telewizjaBiznesowa.png";
import partner7 from "../../assets/images/InvestCuffs.png";

import { useTranslation } from "react-i18next";

function Partners() {

    const { t } = useTranslation();

    return (
        <section className="partners">
            <div className="partners__container">
                <h2 data-aos="fade-up" className="team__title section-title">{ t("Partners Title") }</h2>
                <div className="partners__grid">
                        <PartnerElement image={partner1}/>
                        <PartnerElement image={partner2}/>
                        <PartnerElement image={partner3}/>
                        <PartnerElement image={partner4}/>
                        <PartnerElement image={partner5}/>
                        <PartnerElement image={partner6}/>
                        <PartnerElement image={partner7}/>
                </div>
            </div>
        </section>
    );
}

type Props = {
    className?: string
    image: any
}

function PartnerElement(props: Props) {
    const {
        className = "",
        image
    } = props;

    return (
        <div data-aos="fade-up" className={ `partner ${ className }` }>
            <img src={ image } alt="member-image"/>
        </div>
    );
}

export default (Partners);
