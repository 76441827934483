import HoverImage from "./Image/HoverImage.component";
import hoverImage1 from "../../assets/images/hover_image.png";
import hoverImage2 from "../../assets/images/hover_image_2.png";
import blueBox from "../../assets/images/blue_box.png";
import yellowBox from "../../assets/images/yellow_box.png";
import { useTranslation } from "react-i18next";

function About() {

    const { t } = useTranslation();

    return (
        <section className="about">
            <div className="about__container">
                <h2 data-aos="fade-up" className="about__title section-title">{ t("About Title") }</h2>
                <p data-aos="fade-up" className="about__paragraph text">
                    { t("About Paragraph") }
                    <a href={ t("About Paragraph Link") } className="about__paragraph-link">{ t("About Paragraph Link Text") }</a>
                </p>
                <div className="about__wrapper">
                    <div className="about__images">
                        <HoverImage image={ hoverImage1 } box={ yellowBox } position="left-top"/>
                        <HoverImage image={ hoverImage2 } box={ blueBox } position="right-bottom"/>
                    </div>
                    <div className="about__content">
                        <h3 data-aos="fade-up" className="about__content__title title">
                            <span className="title--secondary">
                            { t("About Subtitle 1") }
                            </span>
                            { t("About Subtitle 2") }
                        </h3>
                        <p data-aos="fade-up" className="about__content__text text">
                            { t("About Content 1") }
                        </p>
                        <p data-aos="fade-up" className="about__content__text text">
                            { t("About Content 2") }
                        </p>
                        <p data-aos="fade-up" className="about__content__text text">
                            { t("About Content 3") }
                        </p>
                        <p data-aos="fade-up" className="about__content__text text">
                            { t("About Content 4") }
                        </p>
                    </div>
                </div>
            </div>
        </section>
    );
}

export default (About);
